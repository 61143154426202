
import { Watch,Mixins,Component, Vue, ProvideReactive } from "vue-property-decorator";
import PrescriptionProductListCom from "./components/productList.vue";

import { presCategoryTypeList, getPresCategoryList } from "@/api/prescriptionDb"

import { cloneDeep } from "lodash";

@Component({
    inject: [],
    components: {
        PrescriptionProductListCom
    }
})
export default class PrescriptionProductList extends Vue {

    @ProvideReactive()
    parentId = 1

    productTypeOptions = [{ label: "项目产品库", value: 1 }, { label: "居家产品库", value: 2 }, { label: "其他库", value: 3 }]
    
    @ProvideReactive()
    merchantId: string|number = 0;

    categoryList = [];

    @ProvideReactive()
    categoryInfo = {} as any;

    categoryLoading = false;

    mounted() {
        this.getData();
    }
    
    handleClick($event) {
        this.getData();
    }
    
    getData() {
      this.categoryLoading = true;
      getPresCategoryList({merchantId: this.merchantId, parentId: this.parentId}).then((res) => {
        this.categoryList = res.data;
        this.categoryInfo = res.data[0];
      }).finally(() => {
        this.categoryLoading = false;
      })
    }

    getProductList(category) {
        this.categoryInfo = cloneDeep(category);
    }

}
